import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appTimeMask]'
})
export class TimeMaskDirective {

  constructor(public ngControl: NgControl) {}

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event: string) {
    const newVal = this.validateHour(event);
    this.ngControl.valueAccessor.writeValue(newVal);
  }

  private validateHour(value: string): string {
    const cleanValue = value.replace(/\D/g, '');
    const hour = cleanValue.substring(0, 2);
    const minutes = cleanValue.substring(2, 4);

    if (parseInt(hour) > 23) {
      return '23' + (minutes ? `:${minutes}` : '');
    } else if (parseInt(hour) === 0 && minutes && parseInt(minutes) > 59) {
      return '00:59';
    } else if (parseInt(minutes) > 59) {
      return hour + ':59';
    }

    return hour + (minutes ? `:${minutes}` : '');
  }
}
