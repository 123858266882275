import { Component } from '@angular/core';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-spinner',
  template: `
  <div class="text-center" *ngIf="isLoading$ | async">
    <div class="spinner-border" role="status">
      <span class="sr-only"> </span>
    </div>
  </div>
  `,
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent{
  isLoading$ = this.spinnerSvc.isLoading$;

  constructor(private spinnerSvc: SpinnerService){}

}
