import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appVehicleMask]'
})
export class VehicleMaskDirective {

  constructor(public ngControl: NgControl) {}

  @HostListener('input', ['$event.target.value'])
  onInputChange(value: string) {
    const newVal = this.validateNumber(value);
    this.ngControl.control.setValue(newVal, { emitEvent: false });
  }

  private validateNumber(value: string): string {
    return value.replace(/[^a-zA-Z0-9]/g, '');
  }
}
