import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CONSTANT } from '../app.constants';
import { Observable } from 'rxjs';
import { ITypeReport } from '../interfaces/type-report-interface';
import { ICompany } from '../interfaces/company-interface';
import { IEmergency } from '../interfaces/emergency-interface';
import { IDamage } from '../interfaces/damage-interface';
import { IAccidentIncident } from '../interfaces/incident-accident-interface';

@Injectable({
  providedIn: 'root'
})
export class IncidentServices {

  constructor(private http: HttpClient) {
  }

  public getTypeReports(): Observable<ITypeReport[]> {
    return this.http.get<ITypeReport[]>(CONSTANT.TYPE_REPORT_METHOD.URL.GET);
  }

  public getCompanies(): Observable<ICompany[]> {
    return this.http.get<ICompany[]>(CONSTANT.COMPANY_METHOD.URL.GET);
  }

  public getEmergencies(): Observable<IEmergency[]> {
    return this.http.get<IEmergency[]>(CONSTANT.EMERGENCY_METHOD.URL.GET);
  }

  public getDamages(): Observable<IDamage[]> {
    return this.http.get<IDamage[]>(CONSTANT.DAMAGES_METHOD.URL.GET);
  }

  public createdIncidentAccident(data: Partial<IAccidentIncident>): Observable<IAccidentIncident> {
    return this.http.post<IAccidentIncident>(CONSTANT.FORM_METHOD.URL.BASE, data);
  }
}
