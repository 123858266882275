import { Injectable } from '@angular/core';
import { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import Swal from 'sweetalert2';

interface IError {
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class SweetAlertHelper {
  createCustomAlert(options: SweetAlertOptions): Promise<SweetAlertResult> {
    return Swal.fire(options);
  }

  captureException(error: IError): Promise<SweetAlertResult> {
    return Swal.fire({ title: 'Error', text: error.message, icon: 'error' });
  }

  showConfirmationAlert(
    title: string,
    text: string,
    confirmButtonText: string,
    cancelButtonText: string
  ): Promise<number> {
    return new Promise<number>(resolve => {
      Swal.fire({
        title: title,
        text: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText
      }).then(result => {
        if (result.isConfirmed) {
          resolve(1);
        } else {
          resolve(0);
        }
      });
    });
  }
}
