import { environment } from "src/environments/environment";

const EndPoint = (resource: string): string => {
  return environment.url_api + resource;
};

export const CONSTANT = {

  TYPE_REPORT_METHOD: {
    URL:{
      BASE: EndPoint('TypeReports'),
      GET: EndPoint('TypeReports/ListByReport')
    }
  },
  COMPANY_METHOD: {
    URL:{
      BASE: EndPoint('Company'),
      GET: EndPoint('Company/ListByCompany')
    }
  },
  EMERGENCY_METHOD: {
    URL:{
      BASE: EndPoint('Emergencies'),
      GET: EndPoint('Emergencies/ListByEmergency')
    }
  },
  DAMAGES_METHOD: {
    URL:{
      BASE: EndPoint('Damages'),
      GET: EndPoint('Damages/ListByDamage')
    }
  },
  FORM_METHOD: {
    URL:{
      BASE: EndPoint('IncidentDatums'),
      GET: EndPoint('IncidentDatums/ListByIncidentDatum')
    }
  }
};