import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RecaptchaModule } from 'ng-recaptcha';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderInterceptor } from './middleware/send-interceptor-service';
import { SpinnerModule } from './shared/spinner/spinner.module';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TimeMaskDirective } from './directives/time-mask.directive';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { IncidentServices } from './services/incident-services.service';
import { NumberPassengersMaskDirective } from './directives/passengers-mask.directive';
import { VehicleMaskDirective } from './directives/vehicle-mask.directive'


@NgModule({
  declarations: [
    AppComponent,
    PhoneMaskDirective,
    TimeMaskDirective,
    NumberPassengersMaskDirective,
    VehicleMaskDirective
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    RecaptchaModule,
    SpinnerModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatNativeDateModule
  ],
  exports: [
    PhoneMaskDirective,
    TimeMaskDirective,
    NumberPassengersMaskDirective
  ],
  providers: [
    IncidentServices,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}

