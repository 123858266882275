import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNumberPassengersMask]'
})
export class NumberPassengersMaskDirective {

  constructor(public ngControl: NgControl) {}

  @HostListener('input', ['$event.target.value'])
  onInputChange(value: string) {
    const newVal = this.validateNumber(value);
    this.ngControl.control.setValue(newVal, { emitEvent: false });
  }

  private validateNumber(value: string): string {
    const cleanValue = value.replace(/[^0-4]/g, '');
    return cleanValue;
  }
 
}