import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IncidentServices } from './services/incident-services.service';
import { ITypeReport } from './interfaces/type-report-interface';
import { ICompany } from './interfaces/company-interface';
import { IDamage } from './interfaces/damage-interface';
import { IEmergency } from './interfaces/emergency-interface';
import { SweetAlertHelper } from './helpers/sweet-alert-helper.service';
import { SpinnerService } from './services/spinner.service';
import { MatDatepicker } from '@angular/material/datepicker';
import { finalize, forkJoin } from 'rxjs';

declare const google;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild('picker') picker: MatDatepicker<Date>;

  public readonly title = 'IncidentFront';
  protected formIncident: FormGroup;
  protected typeReportOptions: ITypeReport[] = [];
  protected companyOptions: ICompany[] = [];
  protected damagesOptions: IDamage[] = [];
  protected emergencyOptions: IEmergency[] = [];
  public autocomplete;
  currentDate: Date;

  constructor(
    private fb: FormBuilder,
    private incidentServices: IncidentServices,
    private sweetAlertHelper: SweetAlertHelper,
    private spinnerServiceService: SpinnerService
  ) {
    this.initForm();
    this.handleControlBySwitches();
  }

  ngOnInit(): void {
    this.fetchAllSelects();
    this.setTimeAndActualDate();
    this.loadAutoComplete();
  }

  private loadAutoComplete(): void {
    const locationControl = this.getControl('location');

    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('location'),
      { types: ['geocode'] }
    );

    this.autocomplete.addListener("place_changed", () => {
      const { formatted_address } = this.autocomplete.getPlace();
      locationControl.setValue(formatted_address);
    });
  }

  private handleControlBySwitches(): void {
    this.disableControlByCheckbox('was911Involved', 'emergencyId');
    this.disableControlByCheckbox('riskNotified', 'riskWho');
  }

  private initForm(): void {
    this.formIncident = this.fb.group({
      time: [null, [
        Validators.required,
        Validators.pattern(/^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/),
        Validators.minLength(5),
        Validators.maxLength(5)
      ]],
      passengersNumber: [null, [
        Validators.required,
        Validators.pattern(/^[0-9]{1,2}$/),
        Validators.minLength(1),
        Validators.maxLength(2)
      ]],
      VehicleNumber: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(10)]],
      DriverPhone: ['', [Validators.required, Validators.minLength(14), Validators.maxLength(14)]],
      location: [null, Validators.required],
      whoNotifiedYou: [null, Validators.required],
      driverName: [null, Validators.required],
      damagesId: [null, Validators.required],
      typeReportId: [null, Validators.required],
      companyId: [null, Validators.required],
      date: [null, Validators.required],
      emergencyId: [{ value: null, disabled: true }, Validators.required],
      anyInjuries: [false],
      wasARoadSupervisorDispatched: [false],
      was911Involved: [false],
      otherVehicleDescription: [null],
      description: [null],
      dispatcher: [null, Validators.required],
      dispatcherPhone: [null, [Validators.required, Validators.minLength(14), Validators.maxLength(14)]],
      riskNotified: [false],
      riskWho: [{ value: null, disabled: true }, Validators.required],
      otherDriversNameAndNumbers: [null]
    });
  }

  private fetchAllSelects(): void {
    this.spinnerServiceService.showSpinner();
    forkJoin({
        typeReportOptions: this.incidentServices.getTypeReports(),
        companyOptions: this.incidentServices.getCompanies(),
        damagesOptions: this.incidentServices.getDamages(),
        emergencyOptions: this.incidentServices.getEmergencies()
      }
    )
      .pipe(finalize(() => this.spinnerServiceService.hideSpinner()))
      .subscribe({
        next: (result): void => {

          const {
            typeReportOptions,
            emergencyOptions,
            damagesOptions,
            companyOptions
          } = result;

          this.typeReportOptions = typeReportOptions;
          this.emergencyOptions = emergencyOptions;
          this.damagesOptions = damagesOptions;
          this.companyOptions = companyOptions;
        },
        error: err => console.log(err)
      });
  }

  private setTimeAndActualDate(): void {
    this.currentDate = new Date();
    this.getControl('date').setValue(this.currentDate);
    this.getActualHour();
  }

  private getActualHour(): void {
    const minutes: number = new Date().getMinutes();
    const hours: number = new Date().getHours();
    let time: string;

    if (hours < 10 && minutes < 10) {
      time = '0' + hours.toString() + ':' + '0' + minutes.toString();
    } else if (minutes < 10) {
      time = hours.toString() + ':' + '0' + minutes.toString();
    } else if (hours < 10) {
      time = '0' + hours.toString() + ':' + minutes.toString();
    } else {
      time = hours.toString() + ':' + minutes.toString();
    }

    this.getControl('time').setValue(time);
  }

  public validateDate() {
    const dateControl = this.getControl('date');
    const timeControl = this.getControl('time');

    const selectedDate: Date = new Date(dateControl.value);
    const currentDate: Date = new Date();
    const isValidDate: boolean = currentDate > selectedDate;

    if (isValidDate) {
      timeControl.enable();
      return;
    }

    dateControl.setErrors({ invalidDate: true });
    timeControl.disable();
  }

  public async submitDataToIncident() {
    const confirmation = await this.showConfirmationModal();

    if (!confirmation) {
      return;
    }

    const body = this.getFormattedIncidentData();

    this.spinnerServiceService.showSpinner();
    this.incidentServices
      .createdIncidentAccident(body)
      .pipe(finalize(() => this.spinnerServiceService.hideSpinner()))
      .subscribe({
        next: (): void => {
          this.showSuccessfullyDialog();
          this.resetForm();
        },
        error: (err) => this.sweetAlertHelper.captureException(err)
      })
  }

  public getYesOrNotLabelFromControl(controlName: string): string {
    return this.getControl(controlName).value ? 'Yes' : 'No';
  }

  private getControl(controlName: string): AbstractControl {
    return this.formIncident.get(controlName);
  }

  private disableControlByCheckbox(controlName: string, controlToEnableOrDisableName: string): void {
    this.getControl(controlName).valueChanges.subscribe({
      next: (value) => {
        const controlToEnableOrDisable = this.getControl(controlToEnableOrDisableName);
        value ? controlToEnableOrDisable.enable() : controlToEnableOrDisable.disable();
      }
    });
  }

  private showConfirmationModal(): Promise<number>  {
    return this.sweetAlertHelper.showConfirmationAlert(
      'Are you sure?',
      'You want to send the form information!',
      'Yes, save it!',
      'No, cancel!'
    );
  }

  private showSuccessfullyDialog(): void {
    this.sweetAlertHelper.createCustomAlert({
      title: 'Success!',
      text: 'The form information has been sent successfully.',
      icon: 'success'
    }).then();
  }

  private resetForm(): void {
    this.formIncident.reset();
    this.setTimeAndActualDate();
  }

  private getFormattedIncidentData() {
    const data = this.formIncident.value;
    return {
      ...data,
      time: data.time + ':00'
    };
  }

  get isInvalidDate(): boolean {
    return this.getControl('date').invalid;
  }

  get disableSubmitBtn(): boolean {
    return this.formIncident.invalid
  }
}
