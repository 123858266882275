<div>
  <app-spinner></app-spinner>
  <div class="container py-4">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="text-center">
          <img src="./assets/ta.jpg" width="250" alt="TA" height="94" />
        </div>
        <h4 class="text-center">Accident/Incident Report</h4>
        <form [formGroup]="formIncident" (ngSubmit)="submitDataToIncident()">

          <div class="mb-3">
            <label for="typeReportId" class="form-label">Type of Report:</label>
            <select formControlName="typeReportId" class="form-select" id="typeReportId">
              <option value="null" disabled>Select..</option>
              <option *ngFor="let option of typeReportOptions" [value]="option.typeReportId">
                {{ option.description }}
              </option>
            </select>
          </div>

          <div class="mb-3">
            <label for="companyId" class="form-label">Company Name:</label>
            <select formControlName="companyId" class="form-select" id="companyId">
              <option value="null" disabled>Select..</option>
              <option *ngFor="let option of companyOptions" [value]="option.companyId">
                {{ option.description }}
              </option>
            </select>
          </div>

          <div class="mb-3">
            <label for="date" class="form-label">Date:</label>

            <div class="input-group">
              <input
                type="text"
                class="form-control"
                id="date"
                placeholder="MM/DD/YYYY"
                formControlName="date"
                [matDatepicker]="picker"
                (dateChange)="validateDate()">
              <button
                class="btn btn-outline-secondary custom-input-button"
                type="button"
                (click)="picker.open()">
                <i class="fas fa-calendar"></i>
              </button>
              <mat-datepicker #picker></mat-datepicker>
            </div>

            <div *ngIf="isInvalidDate" class="text-danger">
              <p>*Date must not be later than the current date</p>
            </div>
          </div>

          <div class="mb-3">
            <label for="time" class="form-label">Time:</label>
            <input
              type="text"
              placeholder="HH:mm"
              maxlength="5"
              formControlName="time"
              class="form-control"
              id="time"
              appTimeMask />
          </div>

          <div class="mb-3">
            <label for="location" class="form-label">Location:</label>
            <input type="text" formControlName="location" class="form-control" id="location" placeholder="Location"/>
          </div>

          <div class="mb-3">
            <label for="whoNotifiedYou" class="form-label">Who Notified You?</label>
            <input
              type="text"
              formControlName="whoNotifiedYou"
              class="form-control"
              id="whoNotifiedYou"
              placeholder="Who notified you?" />
          </div>

          <div class="mb-3">
            <label for="VehicleNumber" class="form-label">Vehicle Number:</label>
            <input
              type="text"
              maxlength="10"
              formControlName="VehicleNumber"
              class="form-control"
              id="VehicleNumber"
              placeholder="Vehicle Number"
              appVehicleMask />
          </div>

          <div class="mb-3">
            <label for="driverName" class="form-label">Driver's Name:</label>
            <input
              type="text"
              formControlName="driverName"
              class="form-control"
              id="driverName"
              placeholder="Driver Name" />
          </div>

          <div class="mb-3">
            <label for="DriverPhone" class="form-label">Driver's Phone #:</label>
            <input
              type="tel"
              maxlength="14"
              formControlName="DriverPhone"
              class="form-control"
              id="DriverPhone"
              placeholder="Driver's Phone #"
              appPhoneMask />
          </div>

          <div class="mb-3">
            <label for="passengersNumber" class="form-label"># Of Passengers:</label>
            <input
              id="passengersNumber"
              type="text"
              maxlength="2"
              formControlName="passengersNumber"
              class="form-control"
              placeholder="# Of Passengers"
              appNumberPassengersMask />
          </div>

          <div class="mb-3">
            <label class="form-label" for="anyInjuries">Were there any injuries?</label>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" formControlName="anyInjuries" id="anyInjuries" />
              <label
                class="form-check-label" for="anyInjuries"
              >
                {{ getYesOrNotLabelFromControl('anyInjuries') }}
              </label>
            </div>
          </div>

          <div class="mb-3">
            <label for="damagesId" class="form-label">Damages:</label>
            <select class="form-select" formControlName="damagesId" id="damagesId">
              <option value="null" disabled>Select..</option>
              <option *ngFor="let option of damagesOptions" [value]="option.damagesId">
                {{ option.description }}
              </option>
            </select>
          </div>

          <div class="mb-3">
            <label class="form-label" for="wasARoadSupervisorDispatched">Was a road supervisor dispatched?</label>
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                formControlName="wasARoadSupervisorDispatched"
                id="wasARoadSupervisorDispatched" />
              <label
                class="form-check-label"
                for="wasARoadSupervisorDispatched"
              >
                {{ getYesOrNotLabelFromControl('wasARoadSupervisorDispatched') }}
              </label>
            </div>
          </div>

          <div class="mb-3">
            <label for="dispatcher" class="form-label">Dispatcher:</label>
            <input
              type="text"
              formControlName="dispatcher"
              class="form-control"
              id="dispatcher"
              placeholder="Dispatcher's name" />
          </div>

          <div class="mb-3">
            <label for="dispatcher-phone" class="form-label">Dispatcher's Phone #:</label>
            <input
              type="tel"
              maxlength="14"
              formControlName="dispatcherPhone"
              class="form-control"
              id="dispatcher-phone"
              placeholder="Dispatcher's Phone #"
              appPhoneMask />
          </div>

          <div class="mb-3">
            <label class="form-label" for="riskNotified">Risk Notified?</label>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" formControlName="riskNotified" id="riskNotified" />
              <label
                class="form-check-label" for="riskNotified"
              >
                {{ getYesOrNotLabelFromControl('riskNotified') }}
              </label>
            </div>
          </div>

          <div class="mb-3">
            <label for="riskWho" class="form-label">If yes, Who?</label>
            <input
              type="text"
              formControlName="riskWho"
              class="form-control"
              id="riskWho"
              placeholder="Risk Who?"
            />
          </div>

          <div class="mb-3">
            <label class="form-label" for="was911Involved">Was 911 Involved?</label>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" formControlName="was911Involved" id="was911Involved" />
              <label
                class="form-check-label"
                for="was911Involved"
              >
                {{ getYesOrNotLabelFromControl('was911Involved') }}
              </label>
            </div>
          </div>

          <div class="mb-3">
            <label for="emergencyId" class="form-label">If yes, Who?</label>
            <select class="form-select" formControlName="emergencyId" id="emergencyId">
              <option value="null" disabled>Select..</option>
              <option *ngFor="let option of emergencyOptions" [value]="option.emergencyId">
                {{ option.descriptionEmergency }}
              </option>
            </select>
          </div>

          <section id="text-areas" class="mt-4">
            <div class="mb-3">
              <label for="otherDriversNameAndNumbers" class="form-label">Other driver's name and numbers:</label>
              <textarea class="form-control" formControlName="otherDriversNameAndNumbers" id="otherDriversNameAndNumbers" placeholder="Leave here"></textarea>
            </div>

            <div class="mb-3">
              <label for="Vehicle-Description" class="form-label">Other Vehicle Description:</label>
              <textarea class="form-control" formControlName="otherVehicleDescription" id="Vehicle-Description" placeholder="Leave a comment here" ></textarea>
            </div>

            <div class="mb-3">
              <label for="Comments" class="form-label">Description</label>
              <textarea class="form-control" formControlName="description" id="Comments" placeholder="Leave a comment here" ></textarea>
            </div>
          </section>

          <div class="text-center">
            <br />
            <button [disabled]="disableSubmitBtn" id="btnSuccess" type="submit" class="btn btn-success btn-lg">Success</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<footer>
  Version 1.0.0 © 2023 Copyright <br>
  2766 NW 62nd ST, Miami, FL 33147 <br>
  <strong>Email:</strong> hello@transportationamerica.com - <strong>Phone:</strong> +1 (305) 871-1111 <br>
  Powered by <a href="https://www.transportationamerica.com/" target="_blank">Transportation America.</a>
</footer>
