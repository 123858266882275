import { Injectable } from '@angular/core';
import { HttpHeaders, HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RequestInterface, ResponseInterface } from '../interfaces/response-interface';
@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<RequestInterface>, next: HttpHandler): Observable<HttpEvent<ResponseInterface>> {
    const headers: HttpHeaders = req.headers.set('Content-Type', 'application/json');
    const authReq = req.clone({ headers });
    return next.handle(authReq);
  }
}